<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
          <!-- <v-icon
            @click="pushRouteToView('bs')"
            class="mr-4"
            style="cursor: pointer"
            title="voltar"
          >
            mdi-arrow-left
          </v-icon>-->
          <v-btn small @click="pushRouteToView()" class="mr-4 btn-back">
            <v-icon>
              mdi-arrow-left
            </v-icon>
            Voltar
          </v-btn>
          <v-icon class="mr-2">mdi-hair-dryer-outline</v-icon>
          {{ beautySpot.id ? 'Editar Beauty Spot' : 'Cadastrar Beauty Spot' }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="createBs" @submit.prevent="newBs(beautySpot.id)" v-model="createBeautySpot">
            <v-col md="12" class="mt-1">
              <v-row>
                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-model="beautySpot.cnpj" label="CNPJ" :rules="cnpjRules"
                    :disabled="beautySpot.id ? true : false" v-mask="['##.###.###/####-##']" outlined dense
                    append-icon="mdi-card-account-details-outline" />
                </v-col>
              </v-row>
              <v-row>
                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-model="beautySpot.companyName" :rules="textRules" label="Razão Social" outlined dense
                    append-icon="mdi-office-building" v-uppercase />
                </v-col>
                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-model="beautySpot.fantasyName" :rules="textRules" label="Nome Fantasia" outlined dense
                    append-icon="mdi-office-building" v-uppercase />
                </v-col>
              </v-row>
              <v-row>
                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-model="beautySpot.phone" v-mask="['(##) ####-####', '(##) #####-####']"
                    :rules="textRules" label="Telefone" outlined dense append-icon="mdi-phone" />
                </v-col>


                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-model="beautySpot.email" :rules="textRules" label="E-mail" outlined dense
                    append-icon="mdi-at" />
                </v-col>
              </v-row>
              <v-row>
                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-model="beautySpot.zipcode" id="cep" name="cep" type="text" v-mask="['#####-###']"
                    @keyup="fillCep" label="CEP" :rules="textRules" outlined dense append-icon="mdi-map-marker" />
                </v-col>
              </v-row>
              <v-row>
                <v-col xl="9" lg="9" md="9" sm="12">
                  <v-text-field v-model="beautySpot.street" label="Endereço" :rules="textRules" outlined dense
                    append-icon="mdi-map-marker" v-uppercase />

                </v-col>
                <v-col xl="3" lg="3" md="3" sm="12">
                  <v-text-field
                    v-model.number="beautySpot.number"
                    type="number"
                    label="Número"
                    :rules="textRules"
                    outlined
                    dense
                    append-icon="mdi-map-marker"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-model="beautySpot.addressComplement" label="Complemento" outlined dense
                    append-icon="mdi-map-marker" v-uppercase />
                </v-col>

                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-model="beautySpot.district" label="Bairro" :rules="textRules" outlined dense
                    append-icon="mdi-map-marker" v-uppercase />
                </v-col>
              </v-row>
              <v-row>
                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-model="beautySpot.city" label="Cidade" :rules="textRules" outlined dense
                    append-icon="mdi-map-marker" v-uppercase />
                </v-col>

                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-select v-model="beautySpot.state" :items="states" :rules="textRules" label="Estado" outlined
                    item-text="state" item-value="acronym" dense append-icon="mdi-map-marker" v-uppercase></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col xl="4" lg="4" md="4" sm="12">
                  <v-select
                    v-model="beautySpot.categories"
                    :items="bsCategories"
                    label="Categorias"
                    :rules="textRules"
                    item-value="id"
                    item-text="desc"
                    multiple
                    hint="Selecione as categorias do Beauty Spot."
                    outlined
                    dense
                    v-uppercase
                  ></v-select>
                </v-col>

                <v-col xl="4" lg="4" md="4" sm="12">
                  <v-text-field v-model.number="beautySpot.lat" label="Latitude" suffix="º" :rules="textRules" outlined
                    dense />
                </v-col>

                <v-col xl="4" lg="4" md="4" sm="12">
                  <v-text-field v-model.number="beautySpot.lon" label="Longitude" suffix="º" :rules="textRules" outlined
                    dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-show="!id" v-model="beautySpot.password" :rules="[passwordRules]" type="password"
                    label="Senha" placeholder="Senha" outlined dense append-icon="mdi-lock" />
                </v-col>

                <v-col xl="6" lg="6" md="6" sm="12">
                  <v-text-field v-show="!id" v-model="confirmPassword" type="password" label="Confirmar Senha"
                    :rules="[passwordConfirmRules]" placeholder="Repita a Senha" outlined dense
                    append-icon="mdi-lock" />
                </v-col>
              </v-row>
              <v-row>
                <v-col xl="3" lg="3" md="3" sm="12">

                  <number-input outlined v-model="beautySpot.planValue" :rules="textRules" label="Valor do plano"
                    placeholder="Informe o Valor do Plano" :options="optionsValue" dense append-icon="mdi-currency-usd">
                  </number-input>
                </v-col>

                <v-col xl="3" lg="3" md="3" sm="12">
                  <v-select v-model="beautySpot.planDay" :items="days" :rules="textRules"
                    label="Dia de Vencimento do Plano" outlined dense></v-select>
                </v-col>

                <v-col xl="3" lg="3" md="3" sm="12">
                  <v-select v-model="beautySpot.balanceDay" :items="days" :rules="textRules"
                    label="Dia de Fechamento do Saldo" outlined dense></v-select>
                </v-col>

                <v-col xl="3" lg="3" md="3" sm="12">
                  <number-input outlined v-model="beautySpot.rateAnticipation" :rules="textRules"
                    label="Taxa de Antecipação" placeholder="Taxa de antecipação de Saldo" append-icon="mdi-percent"
                    :options="optionsRate" dense>
                  </number-input>
                </v-col>

                <v-col xl="3" lg="3" md="3" sm="12">
                  <number-input outlined v-model="beautySpot.rateAnticipationBm" :rules="textRules"
                    label="Taxa de Antecipação do BM" placeholder="Taxa de antecipação de Saldo do BM" append-icon="mdi-percent"
                    :options="optionsRate" dense>
                  </number-input>
                </v-col>

              </v-row>
              <v-row>
                <v-col md="12" class="text-right mr-4">
                  <v-row>
                    <v-col sm="12">
                      <v-btn type="submit" color="success">
                        <v-icon left>
                          mdi-content-save
                        </v-icon>
                        Salvar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { validarCNPJ } from "@/common/cnpj.service";
import { mask } from "vue-the-mask";
import store from "@/store";
import NumberInput from "@/components/NumberInput";

import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";
import {
  BEAUTY_SPOT_GET,
  BEAUTY_SPOT_CREATE,
  BEAUTY_SPOT_UPDATE,
  BEAUTY_SPOT_CATEGORIES_GET
} from "@/store/actions/beautyspots.type";
import { BEAUTY_SPOT_RESET } from "@/store/mutations/beautyspots.type";
import { getCep } from "@/services.js";
export default {
  name: "bsCreate",
  directives: { mask },
  props: {
    id: {
      default: 0,
      type: Number,
      required: false
    }
  },
  components: { NumberInput },
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(BEAUTY_SPOT_RESET);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit(BEAUTY_SPOT_RESET);
    await store.dispatch(BEAUTY_SPOT_CATEGORIES_GET);
    if (to.params.id !== undefined) {
      await store.dispatch(BEAUTY_SPOT_GET, to.params.id);
    }
    return next();
  },

  data: () => ({
    createBeautySpot: false,
    confirmPassword: "",

    days: [5, 15, 25],

    optionsRate: {
      locale: "pt-BR",
      prefix: "",
      length: 4,
      precision: 2,
    },
    optionsValue: {
      locale: "pt-BR",
      prefix: "",
      length: 6,
      precision: 2,
    },

    dataCep: {
      cep: "",
      rua: "",
      bairro: "",
      cidade: "",
      estado: "",
    },


    cnpjRules: [
      v => !!v || "O CNPJ é necessário",
      v => validarCNPJ(v) || "Informe um CNPJ válido."
    ],

    emailRules: [
      //v => !!v || "E-mail é obrigatório",
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Informe um email válido";
          }
        }
        return false;
      }
    ],

    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],

    states: [
      { acronym: "AC", state: "ACRE" },
      { acronym: "AL", state: "ALAGOAS" },
      { acronym: "AP", state: "AMAPÁ" },
      { acronym: "AM", state: "AMAZONAS" },
      { acronym: "BA", state: "BAHIA" },
      { acronym: "CE", state: "CEARÁ" },
      { acronym: "DF", state: "DISTRITO FEDERAL" },
      { acronym: "ES", state: "ESPÍRITO SANTO" },
      { acronym: "GO", state: "GOIÁS" },
      { acronym: "MA", state: "MARANHÃO" },
      { acronym: "MT", state: "MATO GROSSO" },
      { acronym: "MS", state: "MATO GROSSO DO SUL" },
      { acronym: "MG", state: "MINAS GERAIS" },
      { acronym: "PA", state: "PARÁ" },
      { acronym: "PB", state: "PARAÍBA" },
      { acronym: "PR", state: "PARANÁ" },
      { acronym: "PE", state: "PERNAMBUCO" },
      { acronym: "PI", state: "PIAUÍ" },
      { acronym: "RJ", state: "RIO DE JANEIRO" },
      { acronym: "RN", state: "RIO GRANDE DO NORTE" },
      { acronym: "RS", state: "RIO GRANDE DO SUL" },
      { acronym: "RO", state: "RONDÔNIA" },
      { acronym: "RR", state: "RORAIMA" },
      { acronym: "SC", state: "SANTA CATARINA" },
      { acronym: "SP", state: "SÃO PAULO" },
      { acronym: "SE", state: "SERGIPE" },
      { acronym: "TO", state: "TOCANTINS" }
    ]
  }),



  methods: {
    pushRouteToView() {
      if (this.id) {
        this.$router.push({
          name: "beautyspot",
          params: { id: this.id }
        });
      } else {
        this.$router.push({ name: 'bs' });
      }
    },

    fillCep() {
      const cep = this.beautySpot.zipcode.replace(/\D/g, "");
      if (cep.length === 8) {
        getCep(cep).then((response) => {
          this.beautySpot.street = response.data.logradouro;
          this.beautySpot.district = response.data.bairro;
          this.beautySpot.state = response.data.uf;
          this.beautySpot.city = response.data.localidade;
        });
      }
    },

    passwordRules(v) {
      if (this.id) return true;
      return !v ? "Este campo é obrigatório" : true;

      // v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    },
    passwordConfirmRules(v) {
      if (this.id) return true;
      if (!v) {
        return "Este campo é obrigatório";
      }
      if (store.getters.beautySpot.password !== v) {
        return "Senha e confirmação tem que ser iguais.";
      }
      return true;
    },
    newBs(id) {
      if (this.$refs.createBs.validate()) {
        let action = id ? BEAUTY_SPOT_UPDATE : BEAUTY_SPOT_CREATE;
        this.$store.commit(CHANGE_PROGRESS, true);
        this.$store
          .dispatch(action)
          .then(res => {
            this.$store.commit(CHANGE_PROGRESS, false);
            this.$refs.createBs.resetValidation();

            if (id) {
              this.snackbar.snackbar = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Editado com sucesso";
            } else {
              this.snackbar.snackbar = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Adicionado com sucesso";
            }

            if (this.id) {
              this.$router.push({
                name: "beautyspot",
                params: { id: this.id }
              });
              return;
            }

            if (res.data.id) {
              this.$router.push({
                name: "beautyspot",
                params: { id: res.data.id }
              });
            }
          })
          .catch(err => {
            if (err.response.data.code == 48) {
              this.$store.commit(CHANGE_PROGRESS, false);
              this.snackbar.snackbar = true;
              this.snackbar.color = "error";
              this.snackbar.text = "CNPJ já cadastrado";
            } else {
              this.$store.commit(CHANGE_PROGRESS, false);
              this.snackbar.snackbar = true;
              this.snackbar.color = "error";
              this.snackbar.text = "Erro ao salvar Beauty Spot";
            }
          });
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }
    }
  },

  computed: {
    ...mapGetters(["snackbar", "beautySpot", "bsCategories"])
  }
};
</script>
